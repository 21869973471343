import React, {useState, useEffect} from 'react'
import { BeerCrafting, Layout, BigMenu, Seo } from './../components'
import { graphql } from 'gatsby'

const BeerCraftPage = ({data}) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Seo title={'How To Craft Beer - The Beer Crafting Process'}>
            <Layout>
                <BigMenu/>
                <BeerCrafting data={data.datoCmsBeerCrafting}/>
            </Layout>
        </Seo>
    )
}

export default BeerCraftPage

export const query = (graphql`
query {
    datoCmsBeerCrafting {
        title
        headerImage{
            sizes(maxWidth: 1920, imgixParams: { fm: "png" }) {
                ...GatsbyDatoCmsSizes
            }
        }
        article{
            value
        }
    }
}
`)